import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";

export const SpacingStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginSpacing20: {
      margin: theme.spacing(5, 0),
    },
    spacing20: {
      padding: theme.spacing(5, 0),
    },
    spacing40: {
      padding: theme.spacing(10, 0),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(5, 0),
      },
    },
    sideSpacing20: {
      padding: theme.spacing(0, 5),
    },
    sideSpacing40: {
      padding: theme.spacing(0, 10),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(0, 5),
      },
    },
    spacing60: {
      padding: theme.spacing(15, 0),
      [theme.breakpoints.down("xs")]: {
        padding: theme.spacing(10, 0),
      },
      [theme.breakpoints.between("xs", "xs")]: {
        padding: theme.spacing(8, 0),
      },
      [theme.breakpoints.between("sm", "sm")]: {
        padding: theme.spacing(9, 0),
      },
    },
    pb12: {
      paddingBottom: theme.spacing(3),
    },
    pb16: {
      paddingBottom: theme.spacing(4),
    },
    pb20: {
      paddingBottom: theme.spacing(5),
    },
    pb24: {
      paddingBottom: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(3),
      },
    },
    pb32: {
      paddingBottom: theme.spacing(8),
    },
    pb40: {
      paddingBottom: theme.spacing(10),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(5),
      },
    },
    pb80: {
      paddingBottom: theme.spacing(20),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(10),
      },
    },
    pb100: {
      paddingBottom: theme.spacing(25),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(12),
      },
    },
    pr20: {
      paddingRight: theme.spacing(5),
    },
    pl20: {
      paddingLeft: theme.spacing(5),
    },
    pt40: {
      paddingTop: theme.spacing(10),
    },
    mb16: {
      marginBottom: theme.spacing(4),
    },
    mb60: {
      marginBottom: theme.spacing(15),
      [theme.breakpoints.down("xs")]: {
        marginBottom: theme.spacing(10),
      },
      [theme.breakpoints.between("xs", "xs")]: {
        marginBottom: theme.spacing(8),
      },
      [theme.breakpoints.between("sm", "sm")]: {
        marginBottom: theme.spacing(9),
      },
    },
    mb32: {
      marginBottom: theme.spacing(8),
    },
    mt16: {
      marginTop: theme.spacing(4),
    },
    mt32: {
      marginTop: theme.spacing(8),
    },
  })
);
