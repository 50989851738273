import React from 'react';
import { Typography, Grid, Container, Paper, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import ContentsLayout from './ContentsLayout';
import { CareerImages } from '../common/images'
import { SpacingStyles } from './common/SpacingStyle'

const JobStyle = makeStyles((theme: Theme) => createStyles({
  jobBoard: {
    width: '100%',
    height: 340,
    backgroundImage: `url(${CareerImages.job})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  }
}))

const PaperImageStyle = makeStyles((theme: Theme) => createStyles({
  img: {
    display: 'block',
    width: '100%',
    height: 273,
    [theme.breakpoints.down('md')]: {
      height: 190
    },
    objectFit: 'cover',
    objectPosition: 'center',
  },
  board: {
    minHeight: 300,
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.between('sm', 'sm')]: {
      minHeight: 380
    },
    [theme.breakpoints.up('md')]: {
      minHeight: 590
    },
    typo: {
      padding: theme.spacing(6, 0),
      [theme.breakpoints.between('sm', 'sm')]: {
        padding: theme.spacing(3, 0)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(6, 0),

      },

    }
  }
}))

interface PaperCardType {
  title: string,
  description: string,
  imgUrl: string,
  imgAlt: string
}
const PaperCard = ({ title, description, imgUrl, imgAlt }: PaperCardType) => {
  const spacingClasses = SpacingStyles();
  const imgClasses = PaperImageStyle();
  return (
    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} container justifyContent="center">
      <Grid container item xs={11} sm={11} md={10} justifyContent="center">
        <Paper variant="outlined" className={imgClasses.board}>
          <Grid item>
            <img src={imgUrl} alt={imgAlt} className={imgClasses.img} />
          </Grid>
          <Grid item container justifyContent="center" className={spacingClasses.spacing40}>
            <Grid item xs={10} sm={10} md={9} lg={9} xl={9} >
              <Typography variant="h4" >{title}</Typography>
              <Typography variant="body2">{description}</Typography>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

const Career: React.FC = () => {
  const spacingClasses = SpacingStyles();
  const classes = JobStyle()

  return (
    <div>
      <ContentsLayout info={
        {
          bannerImageUrl: CareerImages.careerBanner,
          title: "Career"
        }}>
        <Grid container justifyContent="center" className={spacingClasses.pb40}>
          <Grid item md={8} sm={10} xs={10}>
            <Typography variant="body1" align="center" >AirPlug is a venture-backed technology company based in Seoul, Korea. Airplug develops technologies for connected cars and also provides software solutions. We are looking for great people who can contribute in maintaining and extending the company's leadership in technology.
        </Typography>
          </Grid>
        </Grid>
        <Container maxWidth="lg" className={spacingClasses.pb80}>
          <Typography variant="h2" align="center" style={{ margin: '80px 0' }}>Why work at AirPlug?</Typography>
          <Grid container justifyContent="space-between">
            <PaperCard
              title="Opportunites"
              description="Opportunities are being created right now as revolutionary migrations in fundamentals of vehicular technologies are happening."
              imgUrl={CareerImages.opportunity}
              imgAlt="Opportunities are being created right now as revolutionary migrations in fundamentals of vehicular technologies are happening."
            />
            <PaperCard
              title="Future-oriented goal"
              description="We are focusing on vehicular data communications and software solutions which are essential for the development of current and future connected cars."
              imgUrl={CareerImages.goal}
              imgAlt="We are focusing on vehicular data communications and software solutions which are essential for the development of current and future connected cars."
            />
            <PaperCard
              title="Talented members"
              description="Working in this rapidly growing area with talented AirPlug members will be a great opportunity to turn your smart ideas into reality. "
              imgUrl={CareerImages.members}
              imgAlt="Working in this rapidly growing area with talented AirPlug members will be a great opportunity to turn your smart ideas into reality. "
            />
          </Grid>
        </Container>
      </ContentsLayout>
      <Grid container alignItems="center" className={classes.jobBoard} justifyContent="center">
        <Grid item xs={10} sm={10} md={9} lg={9} xl={9} container justifyContent="space-around" alignItems="center">
          <Grid item xs={12} sm={12} md={9} lg={9} xl={9} style={{paddingTop:8, paddingBottom: 8 }}>
            <Typography variant="subtitle2">We are always looking for talented people who will lead the technology with us.</Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={3} xl={3} container justifyContent="center" style={{paddingTop:8, paddingBottom: 8 }}>
            <Button variant="contained" color="primary" href="https://www.notion.so/airplug/AirPlug-Job-Board-a1db672b3c8944d49192e0910144486c" target="_blank">Go To AirPlug Job Board </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

export default Career