import React, { useCallback, useEffect, useState } from 'react';
// @ts-ignore
import { Link as RouterLink } from 'react-router-dom';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { AppBar, Toolbar, Typography, IconButton, CssBaseline, Link, Container, Hidden, } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { useContextState, useContextDispatch } from '../../store/AppContext';
import { NavMenusType } from '../../store/AppContext'
import Logo from './Logo'
import MobileNav from './MobileNav'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "uppercase"
    },
    navLink: {
      padding: theme.spacing(5, 8),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(0, 2)
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 3)
      },
      [theme.breakpoints.up('lg')]: {
        padding: theme.spacing(5, 4)
      },
    }
  }),
);

const NavBar = () => {
  const classes = useStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
  const state = useContextState();
  const dispatch = useContextDispatch();
  const [hamburgerOpen, setHamburgerOpen] = useState<boolean>(false)

  const setCurrentMenu = useCallback((menu: NavMenusType) => () => {
    dispatch({ type: 'SET_CURRENT_MENU', currentMenu: menu })
  }, [dispatch])

  const handleHamburgerOpen = useCallback(() => {
    setHamburgerOpen(true)
  }, [])
  const handleHamburgerClose = useCallback(() => {
    setHamburgerOpen(false)
  }, [])
  useEffect(() => {
    const pathname = window.location.pathname.slice(1);
    if (state.currentMenu !== pathname && pathname.length > 0) {
      if (pathname.length > 0) {
        dispatch({ type: 'SET_CURRENT_MENU', currentMenu: pathname })
      }
    }

  }, [dispatch, state.currentMenu])

  return (
    <div>
      <CssBaseline />
      <AppBar position={matches ? "static" : "fixed"} color="default" >
        <Toolbar variant="dense">
          <Container maxWidth="xl" className={classes.root}>
            <IconButton edge="start" style={{ display: matches ? 'none' : 'flex' }} color="inherit" aria-label="menu" disabled={matches} onClick={handleHamburgerOpen}>
              <MenuIcon />
            </IconButton>
            <MobileNav
              open={hamburgerOpen}
              onClose={handleHamburgerClose}
            />
            <Hidden xsDown>
              <Typography component="div">
                <Link to="/" component={RouterLink} onClick={setCurrentMenu("main")}>
                  <Logo />
                </Link>
                <Link to="/company" component={RouterLink} onClick={setCurrentMenu("company")} className={`nav ${classes.navLink}${state.currentMenu === 'company' ? ' bold' : ' '}`}>
                  company
                </Link>
                <Link to="/products" component={RouterLink} onClick={setCurrentMenu("products")} className={`nav ${classes.navLink}${state.currentMenu === 'products' ? ' bold' : ' '}`}>
                  products
              </Link>
                <Link to="/solutions" component={RouterLink} onClick={setCurrentMenu("solutions")} className={`nav ${classes.navLink}${state.currentMenu === 'solutions' ? ' bold' : ' '}`}>
                  solutions
              </Link>
                <Link to="/career" component={RouterLink} onClick={setCurrentMenu("career")} className={`nav ${classes.navLink}${state.currentMenu === 'career' ? ' bold' : ' '}`} underline='hover'>
                  career
              </Link>
                <Link to="/contacts" component={RouterLink} onClick={setCurrentMenu("contacts")} className={`nav ${classes.navLink}${state.currentMenu === 'contacts' ? ' bold' : ' '}`} underline='hover' >
                  contacts
              </Link>
              </Typography>
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
  )
}

export default NavBar;