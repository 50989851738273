import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Images } from '../../common/images';

interface LogoStyleType {
  logoStyle?: any,
  titleStyle?: any,
  containerStyle?:any,
  logoClassName?:any,
  titleClassName?:any,
  containerClassName?:any,
}
const defaultStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'inline-block',
      verticalAlign: 'middle',
      marginRight: 20,
      [theme.breakpoints.up('sm')]: {
        marginLeft: 0,
        marginRight: 10
      },
      [theme.breakpoints.up('md')]: {
        marginLeft: 0,
        marginRight: 10
      },
      [theme.breakpoints.up('lg')]: {
        marginLeft: 40,
        marginRight: 20
      },
    },
    logoTitle: {
      width: 45,
      display: 'inline-block',
      marginRight: 8,
      objectFit: 'cover',
      height: '100%',
      [theme.breakpoints.up('sm')]: {
        width: 40,
        marginRight: 4,
      },
    
    },
    logoSymbol: {
      width: 85,
      height: '100%',
      display: 'inline-block',
      objectFit: 'cover',
      [theme.breakpoints.up('sm')]: {
        width: 70,
      },
    },
  }),
);

const Logo = ({logoClassName, titleClassName,containerClassName, logoStyle, titleStyle, containerStyle }: LogoStyleType) => {
  const classes = defaultStyle();
  return (
    <div className={containerClassName ? containerClassName : classes.container} style={containerStyle}>
      <img src={Images.symbol} alt="AirPlug" className={logoClassName ? logoClassName : classes.logoTitle} style={logoStyle}/>
      <img src={Images.logoTitle} alt="AirPlug" className={titleClassName ? titleClassName : classes.logoSymbol} style={titleStyle} />
    </div>
  )
}

export default Logo