import React from 'react';
// @ts-ignore
import { Link as RouterLink } from 'react-router-dom'
import { Typography, Grid, Button } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const SpacingStyles = makeStyles((theme: Theme) => createStyles({
  spacing60: {
    padding: theme.spacing(15, 0)
  },
  spacing20: {
    padding: theme.spacing(5, 0)
  },
  pb20: {
    paddingBottom: theme.spacing(5)
  },
  pb24: {
    paddingBottom: theme.spacing(6)
  },
  pb32: {
    paddingBottom: theme.spacing(8)
  },
  pr8: {
    paddingRight: theme.spacing(2)
  },
  mb32: {
    marginBottom: theme.spacing(8)
  },
}))

const overviewStyle = makeStyles((theme: Theme) => createStyles({
  textContainer: {
    maxWidth: '80%',
    minHeight: 122,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      minHeight: 90,
      maxWidth: '100%'
    },
    [theme.breakpoints.between('md', 'md')] : {
    }
  }
}))

interface OverviewItemProps {
  title: string,
  description: string,
  linkUrl: string,
  handleScrollFunc: any
}
const OverviewItem = ({ title, description, linkUrl, handleScrollFunc }: OverviewItemProps) => {
  const spacingClasses = SpacingStyles();
  const classes = overviewStyle();
  return (
    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
      <Grid container className={spacingClasses.pr8}>
        <Typography variant="h4" className={spacingClasses.pb24}>{title}</Typography>
        <Typography variant="body2" className={classes.textContainer}>
          {description}
        </Typography>
      </Grid>
      <Grid container>
        <Button variant="outlined" color="primary" className={spacingClasses.mb32} onClickCapture={handleScrollFunc} component={RouterLink} to={linkUrl}>
          Learn more</Button>
      </Grid>
    </Grid>
  )
}

export default OverviewItem
