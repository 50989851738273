import React from 'react';
import { Container, Typography, Grid, Avatar, Divider, Link } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { myPalette } from '../common/styles/ApThemeProvider'
import ContentsLayout from './ContentsLayout';
import { CompanyImages } from '../common/images';
import { SpacingStyles } from './common/SpacingStyle'

const AvatarStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    width: 144,
    height: 144,
  }
}))

const GridWrapStyles = makeStyles((theme: Theme) => createStyles({
  innerWrap: {
    [theme.breakpoints.down('sm')] : {
      justifyContent: 'center'
    }
  },
  wrap: {
    [theme.breakpoints.down('sm')] : {
      marginTop: theme.spacing(10)
    }
  }
}))

interface CustomCardProps {
  title: string,
  description: string,
  imgUrl: string,
  imgAlt: string,
  className?: string
}
const CustomCardCopmonent = ({ title, description, imgUrl, imgAlt, className }: CustomCardProps) => {
  const avatarClasses = AvatarStyles();
  const spacingClasses = SpacingStyles();
  const gridClasses = GridWrapStyles();

  return (
    <Grid item md={4} lg={4} xl={4}>
      <Grid container className={className + ' ' + gridClasses.wrap + ' '+spacingClasses.sideSpacing20 }>
        <Grid container justifyContent="center" item xs={12}>
          <Avatar
            src={imgUrl}
            className={avatarClasses.root}
            alt={imgAlt}
          />
        </Grid>
        <Grid container justifyContent="center" item xs={12} className={spacingClasses.pt40 + ' ' + spacingClasses.pb24}>
          <Typography variant="h4" >{title}</Typography>
        </Grid>
        <Typography variant="body2">{description}</Typography>
      </Grid>
    </Grid>
  )
}

interface InvestorsType {
  typoPosition: 'left' | 'right',
  title: string,
  description: string,
  webPage: string,
  logoUrl: string,
  logoAlt: string,
}
const LinkStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'block',
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    '&:hover': {
      color: myPalette.primaryColor
    }
  }
}))

const InvestorsItem = ({ typoPosition, title, description, webPage, logoUrl, logoAlt,  }: InvestorsType ) => {
  const spacingClasses = SpacingStyles();
  const linkclasses = LinkStyles();
  const girdClasses = GridWrapStyles();

  return (
    <Grid container direction={(typoPosition === 'right' ? 'row' : 'row-reverse')} alignItems="center" className={spacingClasses.spacing60 +' '+ girdClasses.wrap}>
      <Grid item md={6} lg={6} xl={6} container justifyContent="center" alignItems="center">
        <Grid item xs={8} sm={8} md={10} lg={10} xl={10}>
          <img src={logoUrl} alt={logoAlt} style={{ display: 'block', objectFit: 'contain', objectPosition: 'center',width: '100%' }} />
        </Grid>
      </Grid>
      <Grid item md={6} lg={6} xl={6} container justifyContent={typoPosition === 'right' ? 'flex-end' : 'flex-start'} className={ girdClasses.innerWrap}>
          <Grid item xs={11} container direction="column">
            <Typography variant="h4" className={spacingClasses.pt40 + ' ' + spacingClasses.pb24}>{title}</Typography>
          <Typography variant="body2">{description}</Typography>
          <Divider style={{ backgroundColor: myPalette.blackOpacity60 }} className={spacingClasses.marginSpacing20} />
          <Typography variant="caption">
            <Link href={webPage} target="_blank" className={linkclasses.root} color="inherit" underline="none">{webPage}</Link>
            </Typography>
          </Grid>         
      </Grid>
    </Grid>
  )
}

const Company: React.FC = () => {
  const spacingClasses = SpacingStyles();
  return (
    <ContentsLayout info={
      {
        bannerImageUrl: CompanyImages.aboutBanner,
        title: "About Us"
      }}>
      <Grid container justifyContent="center" className={spacingClasses.pb80}>
        <Grid item md={8} sm={10} xs={10}>
          <Typography variant="body1" align="center">
          AirPlug develops technologies for connected cars and provides software solutions to deploy them. Our technology enhances the value and utility of vehicles by connecting them to various services on networks and cloud. We are elaborating on bringing the technology to various products by cooperating with Car OEMs and Providers.
          </Typography>
        </Grid>
      </Grid>
    </ContentsLayout>
  )
}

export default Company;
