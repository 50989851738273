import React from 'react';
import ContentsLayout from './ContentsLayout';
import { ProductImages } from '../common/images';
import { SpacingStyles } from './common/SpacingStyle';
import ProductItem from './ProductItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';



const Products: React.FC = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  return (
    <ContentsLayout info={
      {
        bannerImageUrl: ProductImages.productBanner,
        title: "Products"
      }}>
      <ProductItem
        title="SoA Design & Deployment Tool"
        summary="A tool for service design, service instance deployment of vehicle SoA and collaborations between an OEM and tier suppliers for SoA."
        description={`
        - Service Interface Design & Management
        - Service Instance Deployment
        - Vehicle & ECU Configuration
        - Adaptive AUTOSAR & GENIVI+CommonAPI Platform Support
        - FIDL, ARXML Export & Import`}
        typoPostion={matches ? 'center' : 'right'}
        imgUrl={ProductImages.SOA}
        imgAlt="SoA Design & Deployment Tool ScreenShot"
      />
       <ProductItem
        title="SOME/IP Protocol Tester"
        summary="An automated tester for SOME/IP protocol stack conforming to SOME/IP standard and adaptable for OEM specifications."
        description={`
        - Wide Test Coverage : International & OEM Standard Test Spec.
        - Fully Automated Testing & Intuitive Control UI
        - Real-time Monitor & Detailed Report
        - Customer Support by SOME/IP Experts
        `}
        typoPostion={matches ? 'center' : 'left'}
        imgUrl={ProductImages.Tester}
        imgAlt="SOME/IP Protocol Tester Tool ScreenShot"
      />
      <ProductItem
        title="SOME/IP Protocol Analyzer"
        summary="A tool for SOME/IP packet & timing analysis of traffic between all Service Instances in a network."
        description={`
        - SOME/IP Packet Inspection : SD(Entries & Options), Service Data(Header & Payload)
        - SOME/IP Traffic & Flow Analysis
        - Real-time Monitoring & Visualization
        - Statistics`}
        typoPostion={matches ? 'center' : 'right'}
        imgUrl={ProductImages.SOMEIPAnalyzer}
        imgAlt="SOME/IP Protocol Analyzer Tool ScreenShot"
      /> 
      <ProductItem
        title="Service Communication Monitor"
        summary="A verification tool for service communications with coverage including service location & relationship verification, data & timing accuracy checks and other design-deployment discrepancy monitoring."
        description={`
          - Service Flow Verification : Service Location, Relation & Communication Spec. 
          - Data Accuracy Verification
          - Timing Verification : SD Behavior, Period, Jitter & Delay
          - Real-time Analysis & Statistics
          `}
        typoPostion={matches ? 'center' : 'left'}
        imgUrl={ProductImages.ServiceMonitor}
        imgAlt="Service Communication Monitor Tool ScreenShot"
      />
      <div className={SpacingStyles().pb80}></div>
    </ContentsLayout>
  )
}

export default Products