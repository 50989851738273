import React from 'react';
import ContentsLayout from './ContentsLayout';
import { SpacingStyles } from './common/SpacingStyle'
import { SolutionsImages } from '../common/images';
import ProductItem from './ProductItem';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Theme } from '@material-ui/core/styles';

const Solutions: React.FC = () => {
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  return (
    <ContentsLayout info={
      {
        bannerImageUrl: SolutionsImages.solutionBanner,
        title: "Solutions"
      }}>
      <ProductItem
        title="SOA"
        description={`
        SOA is a distributed software and communication architecture. By using SOA in connected car development, vehicles can be more adaptive to customer needs.
        AirPlug is leading the SOA development with Car OEM.
        Our SOA solution suite covers architecture. SOA entity, protocols and sample applications for automotive SOA.`}
        typoPostion={matches ? 'center' : 'right'}
        imgUrl={SolutionsImages.solution1}
        imgAlt="SoA Design & Deployment Tool ScreenShot"
      />
      <ProductItem
        title="App Management Software"
        description={`
        Various vehicle applications can be deployed by the car OEM or installed by passengers after production.
        Applications should be compliant with Car OEM's specifications to be safely deployed in environments with limited computing resources.
        AirPlug applications management software gives support to application providers in developing, verifying and delivering applications in accordance with Car OEM's specifications.
        `}
        typoPostion={matches ? 'center' : 'left'}
        imgUrl={SolutionsImages.solution2}
        imgAlt="SOME/IP Protocol Tester Tool ScreenShot"
      />
      <ProductItem
        title="Gateway to multi-cloud"
        description={`
        Vehicles connect to OEM's cloud and public or private service cloud to update vehicular functions and download personal applications.
        AirPlug is elaborating on bringing its patented communication technology into automobile environments.
        Our Gateway software solution supports packet filtering, session relay, authentication, QoS control, dynamic network utilization and many others. An end-to-end Gateway emulation platform is also provided for the design and the test of the Gateway.`}
        typoPostion={matches ? 'center' : 'right'}
        imgUrl={SolutionsImages.solution3}
        imgAlt="SOME/IP Protocol Analyzer Tool ScreenShot"
      />
      <ProductItem
        title="Advanced IVN"
        description={`
        Ethernet is a key factor in the infrastructure for evolutions in vehicular technologies but is currently defined and operated in a static manner limiting its potentials.
        AirPlug provides solutions for dynamic definition and operation of IVNs thus enabling the network to be efficiently adapted to various applications which will be different vehicle by vehicle.
        Our solution enables software defined configuration of IVN based on QoS monitoring, applicaton deployment, external communication condition and security requirements. 
          `}
        typoPostion={matches ? 'center' : 'left'}
        imgUrl={SolutionsImages.solution4}
        imgAlt="Service Monitor Tool ScreenShot"
      />
      <div className={SpacingStyles().pb80}></div>
    </ContentsLayout>
  )
}

export default Solutions