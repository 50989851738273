import React from 'react';
// @ts-ignore
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ApThemeProvider } from '../common/styles/ApThemeProvider'
import MainPage from './MainPage'
import NavBar from './common/NavBar';
import Company from './Company';
import Products from './Products';
import Solutions from './Solutions';
import Career from './Career';
import Contacts from './Contacts';
import Footer from './common/Footer';

export default () => (

  <Router>
    <Switch>
      <ApThemeProvider>
        <NavBar />
        <Route path="/" exact component={MainPage} />
        <Route path="/company" exact component={Company} />
        <Route path="/products" exact component={Products} />
        <Route path="/solutions" exact component={Solutions} />
        <Route path="/career" exact component={Career} />
        <Route path="/contacts" exact component={Contacts} />
        <Footer />
      </ApThemeProvider>
    </Switch>
  </Router>
)