import React from 'react';
//@ts-ignore
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Grid, Container, Typography, Link, Divider, Hidden } from '@material-ui/core';
import { myPalette, defaultTheme } from '../../common/styles/ApThemeProvider';
import ContentsWrap from './ContentsWrap';
import { SpacingStyles } from './SpacingStyle'
import Logo from './Logo'

const LinkStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'block',
    textTransform: 'capitalize',
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: myPalette.primaryColor
    },
  },
  footerMenu: {
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7125rem'
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      fontSize: '0.8125rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9727rem'
    }
  }
}))
const logoStyle = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: 0,
      verticalAlign: 'bottom'
    },
    logoTitle: {
      width: 135,
      height: '100%',
      display: 'inline-block',
      objectFit: 'cover',
      [theme.breakpoints.between('md', 'md')]: {
        width: 90
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: 75
      },

      [theme.breakpoints.down('xs')]: {
        width: 65,
      },
    },
    logoSymbol: {
      width: 70,
      height: '100%',
      marginRight: 8,
      display: 'inline-block',
      objectFit: 'cover',
      [theme.breakpoints.between('md', 'md')]: {
        width: 55
      },
      [theme.breakpoints.between('xs', 'sm')]: {
        width: 40
      },
      [theme.breakpoints.down('xs')]: {
        width: 35,
        // marginRight: 8
      },
    },
  }),
);

const FooterStyles = makeStyles((theme: Theme) => createStyles({
  footerCaption: {
    [theme.breakpoints.down('xs')]: {
      fontSize: '0.7125rem'
    },
    [theme.breakpoints.between('sm', 'sm')]: {
      fontSize: '0.8125rem'
    },
    [theme.breakpoints.up('md')]: {
      fontSize: '0.9727rem'
    }
  }
}))
const Footer: React.FC = () => {
  const linkClasses = LinkStyles();
  const logoClasses = logoStyle();
  const spacingClasses = SpacingStyles();
  const classes = FooterStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const handleClickScrollTop = React.useCallback((positionX: number, positionY: number) => () => {
    window.scrollTo(0, 0)
    if (positionY !== 0) {
      const goToPosition = setTimeout(() => {
        window.scrollBy({
          top: positionY,
          left: positionX,
          behavior: 'smooth'
        });
        clearTimeout(goToPosition)
      }, 50)
    }
  }, [])

  return (
    <div style={{ backgroundColor: myPalette.lightGrayColor }}>
      <Container maxWidth="xl" className={spacingClasses.pt40 + ' ' + spacingClasses.pb20} >
        <ContentsWrap props={{ isMobile: matches }}>
          <Hidden only={['lg', 'xl']}>
            <Grid container alignItems="center" style={{ padding: defaultTheme.spacing(0, 8) }}>
              <Grid item xs={12} sm={3} md={3}>
                <Logo
                  containerClassName={logoClasses.container}
                  logoClassName={logoClasses.logoSymbol}
                  titleClassName={logoClasses.logoTitle}
                />
              </Grid>
              <Grid item xs={12} sm={8} md={9}>
                <Grid container justifyContent="flex-start" spacing={4}>
                  <Grid item>
                    <Typography variant="body2" className={linkClasses.footerMenu}>
                      <Link to="/company" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>company</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={linkClasses.footerMenu}>
                      <Link to="/products" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>products</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={linkClasses.footerMenu}>
                      <Link to="/solutions" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>solutions</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={linkClasses.footerMenu}>
                      <Link to="/career" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>career</Link>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body2" className={linkClasses.footerMenu}>
                      <Link to="/contacts" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>Contacts</Link>
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction="column">
                <Grid item style={{ lineHeight: 1.1 }}>
                  <Typography variant="caption" gutterBottom className={classes.footerCaption}>B-1407, 128 Beobwon-ro, Songpa-gu, Seoul, 05854, Rep. of KOREA
  (Munjeong SK V1 GL METROCITY)</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption" gutterBottom className={classes.footerCaption}>T. +82-2-3454-1760 | </Typography>
                  <Typography variant="caption" gutterBottom className={classes.footerCaption}>F. +82-2-508-4704</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="caption"><Link href="mailto:info@airplug.com" color="inherit" underline="none" className={linkClasses.root} style={{ width: 'fit-content' }}>info@airplug.com</Link></Typography>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#999', margin: defaultTheme.spacing(4, 8) }}/>
            <Typography variant="caption" style={{margin: defaultTheme.spacing(0, 8), whiteSpace: 'nowrap' }}>© 2023. Airplug. all rights reserved.</Typography>
          </Hidden>
          <Hidden only={['xs', 'sm', 'md']}>
            <Grid container>
              <Grid item md={6} lg={6} xl={6}>
                <Logo
                  containerClassName={logoClasses.container}
                  logoClassName={logoClasses.logoSymbol}
                  titleClassName={logoClasses.logoTitle}
                />
                <Grid container item direction="column" style={{ marginLeft: 22, marginTop: 8, maxWidth: '80%' }}>
                  <Typography variant="caption" gutterBottom>B-1407, 128 Beobwon-ro, Songpa-gu, Seoul, 05854, Rep. of KOREA
(Munjeong SK V1 GL METROCITY)</Typography>
                  {/* <Typography variant="caption" gutterBottom>Junmo Koo</Typography> */}
                  <Typography variant="caption" gutterBottom>T. +82-2-3454-1760</Typography>
                  <Typography variant="caption" gutterBottom>F. +82-2-508-4704</Typography>
                  <Typography variant="caption"><Link href="mailto:info@airplug.com" color="inherit" underline="none" className={linkClasses.root} style={{ width: 'fit-content' }}>info@airplug.com</Link></Typography>
                </Grid>
              </Grid>
              <Grid item md={6} lg={6} xl={6}>
                <Grid container spacing={4}>
                  <Grid item container md={4} lg={4} xl={4} direction="column">
                    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>Company</Typography>
                    <Typography variant="caption" >
                      <Link to="/company" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>About Us</Link>
                      <Link to="/company" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 800)}>Management</Link>
                      <Link to="/company" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 1800)}>Investors
                     </Link>
                    </Typography>
                  </Grid>
                  <Grid item container md={4} lg={4} xl={4} direction="column">
                    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>Products</Typography>
                    <Typography variant="caption" >
                      <Link to="/products" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 350)}>SoA Design & Deployment Tool</Link>
                      <Link to="/products" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 950)}>SOME/IP Protocol Tester</Link>
                      <Link to="/products" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 1550)}>SOME/IP Protocol Analyzer
                     </Link>
                      <Link to="/products" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 2018)}>Service Communication Monitor
                     </Link>
                    </Typography>
                  </Grid>
                  <Grid item container md={4} lg={4} xl={4} direction="column">
                    <Typography variant="body2" style={{ fontWeight: 'bold' }} gutterBottom>Solutions</Typography>
                    <Typography variant="caption" >
                      <Link to="/solutions" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 350)}>Service Oriented Architecture (SOA)</Link>
                      <Link to="/solutions" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 900)}>Application Software Management</Link>
                      <Link to="/solutions" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 1400)}>Gateway to Multi-cloud
                     </Link>
                      <Link to="/solutions" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 1800)}>Advanced In-Vehicle Network
                     </Link>
                    </Typography>
                  </Grid>
                  <Grid container className={spacingClasses.mt16}>
                    <Grid item container md={4} lg={4} xl={4} direction="column">
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        <Link to="/career" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)} >Career</Link>
                      </Typography>
                    </Grid>
                    <Grid item container md={4} lg={4} xl={4} direction="column">
                      <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                        <Link to="/contacts" component={RouterLink} color="inherit" underline="none" className={linkClasses.root} onClickCapture={handleClickScrollTop(0, 0)}>Contacts</Link>
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Divider style={{ backgroundColor: '#999', margin: defaultTheme.spacing(4, 8) }} />
            <Typography variant="caption">© 2023. AirPlug. all rights reserved.</Typography>
          </Hidden>
        </ContentsWrap>
      </Container>
    </div>
  )
}

export default Footer