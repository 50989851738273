import React from 'react';
// @ts-ignore
import { Link as RouterLink } from 'react-router-dom';
import { List, ListItem, ListItemIcon, ListItemText, Drawer } from '@material-ui/core'
import { CloseRounded } from '@material-ui/icons'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Logo from './Logo'

const DrawerMenuStyle = makeStyles((theme:Theme) => createStyles({
  list: {
    width: 230,
  },
  menuText: {
    textTransform: 'uppercase',
  }
}));

interface DrawerMenuProps {
  open: boolean,
  onClose: any,
}

const MoblieNav = ({ open, onClose }: DrawerMenuProps) => {
  const classes = DrawerMenuStyle();
  
  const handleOnclose = React.useCallback(() => {
    window.scrollTo(0, 0)
    onClose();
  }, [onClose])

  return (
    <React.Fragment>
      <Drawer
        anchor="left"
        open={open}
        onClose={onClose}
      >
        <div
          className={classes.list}
          role="presentation"
        >
          <List component="div">
            <ListItem component="div">
              <ListItemIcon onClick={onClose}>
                <CloseRounded />
              </ListItemIcon>
            </ListItem>
            <ListItem component={RouterLink} to="/" onClick={handleOnclose}>
              <Logo />
            </ListItem>
          </List>
          <List>
            {['company', 'products', 'solutions', 'career', 'contacts'].map((text) => (
              <ListItem button key={text} component={RouterLink} to={`/${text}`} onClick={handleOnclose}>
                <ListItemText primary={text} className={classes.menuText} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </React.Fragment>
  )
}

export default MoblieNav