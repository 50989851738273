import React from 'react';
import Routes from './components/Routes';
import { ContextProvider } from './store/AppContext'

function App() {
  return (
    <div className="App">
      <ContextProvider>
        <Routes />
      </ContextProvider>
    </div>
  );
}

export default App;
