import React from 'react';

const ContentsWrap = ({ children, props }: { children: React.ReactNode, props: any }) => {
  const { isMobile } = props

  const PCStyle = {
    margin: '0 80px'
  }

  const MobileStyle = {
    margin: '0 10px'
  }
  return (
    <div style={isMobile ? MobileStyle : PCStyle}>
      {children}
    </div>
  )
}

export default ContentsWrap