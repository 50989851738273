import React from 'react';
import { Typography, Container, CssBaseline } from '@material-ui/core'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

interface InfoType {
  bannerImageUrl: any,
  title: string
}

const bannerImageStyle = makeStyles((theme: Theme) => createStyles({
  root: {
    position: "relative",
    width: '100%',
    maxHeight: 256,
    [theme.breakpoints.down('xs')] : {
      maxHeight: 80,
      marginTop: 30,
    },
    [theme.breakpoints.between('sm', 'sm')] : {
      maxHeight: 150,
    },
    [theme.breakpoints.between('md', 'md')] : {
      maxHeight: 200
    },
    '& img': {
      width: '100%',
      objectFit: 'cover',
      objectPosition: 'center'
    }
  },
  title: {
    margin: theme.spacing(20, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(10, 0)
    }
  }
}))

const ContentsLayout = ({ children, info }: { children: React.ReactNode, info: InfoType }) => {

  const bannerClasses = bannerImageStyle();
  return (
    <div>
      <CssBaseline />
      <div className={bannerClasses.root}>
        <img src={info.bannerImageUrl} alt={`${info.title} top banner`} />
      </div>
      <Container maxWidth="lg">
        <Typography variant="h2" align="center" className={bannerClasses.title}>
          {info.title}
        </Typography>
        {children}
      </Container>
    </div>
  )
}

export default ContentsLayout