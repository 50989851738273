import React from 'react';
import { Grid, Typography, Link } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { myPalette } from '../common/styles/ApThemeProvider';

import ContentsLayout from './ContentsLayout';
import { ContactsImages } from '../common/images'
import { SpacingStyles } from './common/SpacingStyle'

const LinkStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'block',
    transition: theme.transitions.create(['color'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginBottom: theme.spacing(1),
    '&:hover': {
      color: myPalette.primaryColor
    }
  }
}))

const MapStyle = makeStyles((theme:Theme) => createStyles({
  container: {
    position: 'relative', 
    width: '100%', 
    height: 500,
    [theme.breakpoints.down('xs')]: {
      height: 300
    },
    [theme.breakpoints.between('sm', 'md')]:{
      height: 430,
    }
  }
}))

const Contacts: React.FC = () => {
  const spacingClasses = SpacingStyles();
  const linkClasses = LinkStyles();
  const mapClasses = MapStyle();
  return (
    <ContentsLayout info={
      {
        bannerImageUrl: ContactsImages.contactsBanner,
        title: "Contacts"
      }}>
      <Grid container alignItems="center" className={spacingClasses.spacing60}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} container className={spacingClasses.spacing20}>
          <Grid item xs={11} className={spacingClasses.mb60}>
            <Typography variant="h4" className={spacingClasses.pb24}>Address (Seoul)</Typography>
            <Typography variant="body2" className={spacingClasses.pb16}>B-1407, 128 Beobwon-ro, Songpa-gu, Seoul, 05854, Rep. of KOREA
            (Munjeong SK V1 GL METROCITY)
            </Typography>
            <Typography variant="body2">T. +82-2-3454-1760</Typography>
            <Typography variant="body2">F. +82-2-508-4704</Typography>
          </Grid>
          <Grid item xs={11}>
            <Typography variant="h4" className={spacingClasses.pb24}>E-mail contact</Typography>
            <Typography variant="body2" className={spacingClasses.pb16}>General inquiry :
              <Link href="mailto:info@airplug.com" color="inherit" underline="none" className={linkClasses.root} style={{width: 'fit-content'}}>
                info@airplug.com
              </Link>
            </Typography>
            <Typography variant="body2">Sales info :
              <Link href="mailto:sales@airplug.com" color="inherit" underline="none" className={linkClasses.root} style={{width: 'fit-content'}}>
                sales@airplug.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <div className={mapClasses.container}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1582.9580629952309!2d127.11921125815614!3d37.48630559495326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzfCsDI5JzEwLjciTiAxMjfCsDA3JzEzLjEiRQ!5e0!3m2!1sen!2skr!4v1599812271272!5m2!1sen!2skr" width='100%' height='100%' frameBorder={0} style={{border: 0}} allowFullScreen tabIndex={0} title="Airplug Address, B-1407, 128 Beobwon-ro, Songpa-gu, Seoul, 05854, Rep. of KOREA (Munjeong SK V1 GL METROCITY) " aria-hidden="false"></iframe>
          </div>
        </Grid>
      </Grid>
    </ContentsLayout>
  )
}


export default Contacts