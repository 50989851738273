import React from 'react';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';
import orange from '@material-ui/core/colors/orange';
import blue from '@material-ui/core/colors/blue'
import { ThemeProvider } from '@material-ui/styles';

const myPalette = {
  primaryColor: '#18A0FB',
  secondaryColor: '#3693c3',
  disabledGrayColor: '#bbb',
  lightGrayColor: '#f5f5f5',
  black: '#111111',
  gray: '#828282',
  pureBlack: '#000',
  pureWhite: '#fff',
  googleBlue: blue[500],
  googleGreen: green[500],
  googleOrange: orange[500],
  googleRed: red[500],
  googleDisalbed: 'rgba(0,0,0,.26)',
  boarderGray: 'rgb(224, 224, 224)',
  blackOpacity60: 'rgba(0, 0, 0, 0.6)',
  blackOpacity80: 'rgba(0, 0, 0, 0.8)',
  opacityBlue: '#2196f333',
  opacityGreen: '#4caf5033',
  opacityOrange: '#ff980033',
  opacityRed: '#f4433633',
}

let defaultTheme = createTheme({
  spacing: 4,
  breakpoints: {
    values: {
      xs: 0,
      sm: 680,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
  palette: {
    primary: {
      light: myPalette.secondaryColor,
      main: myPalette.primaryColor,
    },
    secondary: {
      light: myPalette.lightBlue,
      main: myPalette.secondaryColor,
      dark: myPalette.primaryColor,
    },
  },
  typography: {
    fontFamily: [
      "Montserrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: 14,
    h1: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '2.5rem',
      lineHeight: 1.43,
      letterSpacing: "-0.015em",
      color: myPalette.black,
    },
    h2: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '2rem',
      lineHeight: 1.5,
      letterSpacing: "-0.015em",
      color: myPalette.black,
    },
    h4: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: '1.5rem',
      lineHeight: 1.5,
      letterSpacing: "-0.015em",
      color: myPalette.black,
    },
    subtitle1: {
      // Subheader1
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: '2rem',
      lineHeight: 1.39,
      letterSpacing: "-0.015em",
      color: myPalette.black,
    },
    subtitle2: {
      // p Large
      fontFamily: "Montserrat",
      fontVariant: "p",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '1.98rem',
      lineHeight: 1.39,
      letterSpacing: "-0.015em",
      color: myPalette.black,
    },
    body1: {
      // p normal
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '1.3rem',
      lineHeight: 1.29,
      letterSpacing: "-0.015em",
      color: myPalette.blackOpacity80,
    },
    body2: {
      // p
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '1.025rem',
      lineHeight: 1.8,
      letterSpacing: "-0.015em",
      color: myPalette.blackOpacity80,
    },
    caption: {
      fontFamily: "Montserrat",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: '0.8rem',
      lineHeight: 1.5,
      letterSpacing: "-0.015em",
      color: myPalette.blackOpacity60,
    },
  },
  overrides: {
    // Style sheet name ⚛️
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none",
        },
      },
    },
    MuiAppBar: {
      root: {
        boxShadow: "none",
      },
    },
    MuiButton: {
      // Name of the rule
      root: {
        padding: "12px 16px",
        minWidth: 120,
        "&.small": {
          minWidth: 50,
        },
      },
      outlinedPrimary: {
        '& .MuiButton-label a' : {
          color: myPalette.primaryColor
        },
        "&:hover": {
          backgroundColor: myPalette.primaryColor,
          color: myPalette.pureWhite,
        },
        '&:hover .MuiButton-label a':{
          color: myPalette.pureWhite,
        }
      },
      containedPrimary: {
        backgroundColor: myPalette.primaryColor,
        color: myPalette.pureWhite,
        boxShadow: 'none',
        "&:hover": {
          backgroundColor: myPalette.pureWhite,
          border: `1px solid ${myPalette.primaryColor}`,
          color: myPalette.primaryColor,
          boxShadow: 'none'
        },
      },
    },
    MuiContainer: {
      root: {
       padding: '0 80px',
       '@media (min-width:600px)': {
         padding: '0 20px'
       }
      },
      maxWidthXl : {
        padding: '0 80px',
        '@media (max-width: 960px) and (min-width: 600px)':{
          padding: '0 10px'
        },
        '@media (max-width:600px)': {
          padding: '0 10px'
        }
      }
    },
    MuiDialog: {
      paperWidthLg: {
        minWidth: 960,
      },
    },
    MuiSvgIcon: {
      colorAction: {
        color: myPalette.googleGreen,
        "&:hover": {
          color: myPalette.googleGreen + myPalette.grayBlack,
        },
      },
      colorDisabled: {
        color: myPalette.disabledGrayColor,
      },
    },
    MuiIconButton: {
      colorPrimary: {
        color: myPalette.primaryColor,
        "&:hover": {
          color: myPalette.secondaryColor,
        },
      },
      colorInherit: {
        color: myPalette.disabledGrayColor,
        "&:hover": {
          color: myPalette.grayBlack,
        },
      },
    },
    MuiInput: {
      underline: {
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: `2px solid ${myPalette.secondaryColor}`,
        },
      },
    },
    MuiLink: {
      root: {
        "&.nav": {
          display: 'inline-block',
          fontFamily: "Montserrat",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: 15,
          lineHeight: 1.5,
          letterSpacing: "-0.015em",
          color: myPalette.primaryColor,
          '&.bold' : {
            fontWeight: 'bold'
          }
        },
      },
    },
    MuiTypography: {
      root: {
        textShadow: `1px 1px 1px rgba(0,0,0,0.004)`
      },
    }
  },
});

defaultTheme = responsiveFontSizes(defaultTheme)

const ApThemeProvider = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      {children}
    </ThemeProvider>)
}

export { ApThemeProvider, myPalette, defaultTheme };