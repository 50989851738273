import React, { useState, useEffect } from 'react';
import { Container, Typography, Grid, CssBaseline, List, ListItem, ListItemAvatar, ListItemText, Avatar, Button} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { DriveEta, Settings, Create } from '@material-ui/icons'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { myPalette } from '../common/styles/ApThemeProvider'
import { Images } from '../common/images';
import { SpacingStyles } from './common/SpacingStyle'

import ContentsWrap from './common/ContentsWrap';
import OverviewItem from './OverviewItem';
// import ModalPopup from './ModalPopup';

const JumbotronStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 720,
      backgroundImage: `url(${Images.heroImg2})`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      [theme.breakpoints.between('xs', 'xs')] : {
        height: 380
      },
      [theme.breakpoints.between('sm','sm')] : {
        height: 500
      },
      [theme.breakpoints.between('md', 'md')] : {
        height: 680
      },
    },
    typoContainer: {
      width: '100%',
      height: '100%',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'top right'
    },
    typo: {
      color: myPalette.pureWhite,
    },
    avatarColor: {
      backgroundColor: myPalette.pureWhite
    },
    explainArea: {
      backgroundColor: myPalette.secondaryColor,
      borderRight: `1px solid ${myPalette.pureWhite}`
    },
    explainTypo: {
      color: myPalette.pureBlack
    }
    
  })
)

const MainPageStyle = makeStyles((theme: Theme) => createStyles({
  logoSvg: {
    display: 'inline-block', 
    width: 'calc(35% - 4px)',
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      width: 'calc(25% - 4px)',
    },
    [theme.breakpoints.between('sm', 'md')] : {
      width: 'calc(30% - 4px)'
    }
  },
  logoTitleSvg: {
    display: 'inline-block', 
    width: '60%', 
    [theme.breakpoints.down('sm')] : {
      width: '40%'
    },
    [theme.breakpoints.between('sm', 'md')] : {
      width: '55%'
    }
  },
  aboutUsContainer: {
    padding: theme.spacing(0, 11),
    [theme.breakpoints.down('sm')]:{
      padding: theme.spacing(5, 0)
    },
    [theme.breakpoints.between('sm', 'md')] : {
      padding: theme.spacing(0, 0)
    }
  }
}))

const Jumbotron = () => {
  const classes = JumbotronStyles();
  return (
    <div className={classes.root}>
      <Grid item container className={classes.typoContainer} alignItems="center" justifyContent="center">
        <Typography variant="h1" className={classes.typo} align="center">
        AirPlug is Leading the Technologies for Connected Cars
        </Typography>
      </Grid>
    </div>
  )
}

const MainPage: React.FC = () => {
  const classes = MainPageStyle();
  const SpacingClasses = SpacingStyles();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  // const [isModalOpen, setIsModalOpen] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsModalOpen(true);
  //   }, 1500);

  //   return () => clearTimeout(timer); // Clear the timer if the component unmounts
  // }, []); // Empty dependency array ensures this effect runs only once on mount


  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  const handleClickScrollTop = React.useCallback((positionX:number, positionY:number) => () => {
    window.scrollTo(0, 0)
    if (positionY !== 0) {
      const goToPosition = setTimeout(() => {
        window.scrollBy({
          top: positionY,
          left: positionX,
          behavior: 'smooth'
        });
        clearTimeout(goToPosition)
      }, 100)
    }
}, [])

  return (
    <div>
      {/* <Button variant="text" color="primary" onClick={openModal}>신주발행공고</Button> */}
      {/* <ModalPopup isOpen={isModalOpen} onClose={closeModal}/> */}
      <CssBaseline />
      <Jumbotron />
      <Container maxWidth="lg">
        <ContentsWrap props={{isMobile: matches}}>
          <Grid container className={SpacingClasses.spacing60}>
            <Grid item container  xl={6} lg={6} md={6} sm={5} xs={12} justifyContent="center" alignItems="center">
                  <img src={Images.symbol} alt="AirPlug" className={classes.logoSvg} />
                  <img src={Images.logoTitle} alt="AirPlug" className={classes.logoTitleSvg} />
            </Grid>
            <Grid container direction="column" item xl={6} lg={6} md={6} sm={7} xs={12} justifyContent="center" className={classes.aboutUsContainer}>
              <Typography variant="body2" className={SpacingClasses.pb20}>
              AirPlug is a pioneer in automotive Service Oriented Architecture developing technologies for connected cars.
                </Typography>
              <Grid container spacing={ matches ? 0 : 2}>
                <Grid item>
                  <List dense>
                    <ListItem>
                      <ListItemText
                        primary="Our SOA-based connected car solutions enable, "
                        primaryTypographyProps={{
                          variant: 'body2'
                        }}
                      />
                    </ListItem>
                  </List>
                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <DriveEta />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="always best-connected vehicles, " primaryTypographyProps={{
                          variant: 'caption'
                        }}
                      />
                    </ListItem>
                  </List>
                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Settings />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="efficient deployments of new vehicular functions,"
                        primaryTypographyProps={{
                          variant: 'caption'
                        }}
                      />
                    </ListItem>
                  </List>
                  <List dense>
                    <ListItem>
                      <ListItemAvatar>
                        <Avatar>
                          <Create />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary="and application-driven vehicle feature customization."
                        primaryTypographyProps={{
                          variant: 'caption'
                        }}
                      />
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container className={SpacingClasses.spacing60}>
            <Typography variant="h2" className={SpacingClasses.pb32}>
              Solution Overview
              </Typography>
            <Grid container>
              <OverviewItem
                title="Service Oriented Architecture (SOA)"
                description="Solution suite for the design, implementation, test and verification of SOA."
                linkUrl="/solutions"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 350)}
              />
              <OverviewItem
                title="Application Software Management"
                description="Solutions for the development of SOA-based vehicle applications."
                linkUrl="/solutions"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 900)}
              />
              <OverviewItem
                title="Gateway to Multi-cloud"
                description="Software solution equipped with functions required for connecting in-vehicle applications to cloud servers."
                linkUrl="/solutions"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 1400)}
              />
              <OverviewItem
                title="Advanced In-Vehicle Network"
                description="Solutions for dynamically-defined Ethernet IVN facilitating policies for QoS, application, external communications and security."
                linkUrl="/solutions"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 1800)}
              />
            </Grid>
          </Grid>
          <Grid container className={SpacingClasses.spacing60}>
            <Typography variant="h2" className={SpacingClasses.pb32}>
              Product Overview
              </Typography>
            <Grid container>
              <OverviewItem
                title="SoA Design & Deployment Tool"
                description="A tool for service design, service instance deployment of vehicle SoA and collaborations between an OEM and tier suppliers for SoA"
                linkUrl="/products"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 350)}
              />
              <OverviewItem
                title="SOME/IP Protocol Tester"
                description="An automated tester for SOME/IP protocol stack conforming to SOME/IP standard and adaptable for OEM specifications
                "
                linkUrl="/products"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 950)}
              />
              <OverviewItem
                title="SOME/IP Protocol Analyzer"
                description="A tool for SOME/IP packet & timing analysis of traffic between all Service Instances in a network."
                linkUrl="/products"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 1550)}
              />
              <OverviewItem
                title="Service Communication Monitor"
                description="A verification tool for service communications with coverage including service location & relationship verification, data & timing accuracy checks and other design-deployment discrepancy monitoring."
                linkUrl="/products"
                handleScrollFunc={matches ? handleClickScrollTop(0, 0) : handleClickScrollTop(0, 2018)}
              />
            </Grid>
          </Grid>
        </ContentsWrap>
      </Container>
    </div>
  )
}

export default MainPage