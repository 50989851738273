import React, { useReducer, useContext, createContext, Dispatch } from 'react';

type theme = 'light' | 'dark';

const NavMenus = {
  main: 'main',
  company : 'company', 
  products : 'products', 
  solutions : 'solutions',
  career : 'career',
  contacts :  'contacts'
} as const;
export type NavMenusType = typeof NavMenus[keyof typeof NavMenus]; 

type State = {
  theme: theme ,
  currentMenu: NavMenusType | string,
};
type Actions = | { type: 'SET_CURRENT_MENU', currentMenu: NavMenusType | string } | { type: 'SET_THEME', theme: theme }
type DispatchActionsType = Dispatch<Actions>
const StateContext = createContext<State | null>(null);
const DispatchContext = createContext<DispatchActionsType | null>(null)

// TYPE 선언
function reducer(state: State, action: Actions) : State  {
  switch (action.type) {
    case 'SET_CURRENT_MENU':
      return {
        ...state,
        currentMenu: action.currentMenu
      }
    case 'SET_THEME':
      return {
        ...state,
        theme: action.theme
      }
    default:
      throw new Error('Undefined action!')
  }
}

export function ContextProvider({children} : {children: React.ReactNode}){
  const [state, dispatch] = useReducer(reducer, {
    theme: 'light',
    currentMenu: NavMenus.main
  })
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  )
}

// Custom Hooks
export function useContextState() {
  const state = useContext(StateContext);
  if (!state) throw new Error('Cannot find SampleProvider'); 
  return state;
}

export function useContextDispatch() {
  const dispatch = useContext(DispatchContext);
  if (!dispatch) throw new Error('Cannot find SampleProvider');
  return dispatch;
}