import logoTitle from './AirPlug_logo.svg';
import symbol from './AirPlug_symbol.svg';
import heroImg from './hero_image.jpg';
import heroImg2 from './hero_image_2.jpg';
import aboutBanner from './aboutus-banner.jpg';
import solutionBanner from './solution-banner.jpg';
import productBanner from './product-banner.jpg';
import careerBanner from './career-banner.jpg';
import contactsBanner from './contact-banner.jpg';

// Company Image
import DrJohn from './people2.png';
import DrSJ from './people3.png';
import DrNahm from './people6.png';

import hyundaiLogo from './hyundai-motor-company-logo.svg';
import stormLogo from './storm_ventures_logo.jpg';
import transLinkLogo from './masthead_logo.png'

// Products
import SOA from './SoA_Design_Deployment_Tool_S.jpg'
import Tester from './Tester_main_S.jpg';
import SOMEIPAnalyzer from './SOMEIP-Protocol-Analyzer_S.jpg';
import ServiceMonitor  from './Service-Communication-Monitor_S.jpg'

// Solutions
import solution1 from './20200907_H_Solution 1.svg';
import solution2 from './20200907_H_Solution 2.svg';
import solution3 from './20200907_H_Solution 3.svg';
import solution4 from './20200907_H_Solution 4.svg';

// Career
import goal from './goal.jpg';
import opportunity from './opportunites.jpg';
import members from './members.jpg';
import job from './job.jpg';

// Popup for modal
import popup230829 from './popup(230829).jpg';


export const Images = {
  logoTitle,
  symbol,
  heroImg,
  heroImg2,
}

export const CompanyImages = {
  aboutBanner,
  DrJohn,
  DrSJ,
  DrNahm,
  hyundaiLogo,
  stormLogo,
  transLinkLogo,
}

export const ProductImages = {
  productBanner,
  SOA,
  Tester,
  SOMEIPAnalyzer,
  ServiceMonitor,
}

export const SolutionsImages = {
  solutionBanner,
  solution1,
  solution2,
  solution3,
  solution4,
}

export const CareerImages = {
  careerBanner,
  job,
  goal,
  opportunity,
  members
}

export const ContactsImages = {
  contactsBanner,
}

export const PopupImages = {
  popup230829
}