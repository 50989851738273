import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/core/styles'
import { SpacingStyles } from './common/SpacingStyle';
import { myPalette } from '../common/styles/ApThemeProvider';

interface ProductItemType {
  title: string,
  description: string,
  summary?: string,
  imgUrl: string,
  imgAlt: string,
  typoPostion: 'left' | 'right' | 'center' | 'default'
}

interface TypeMapping {
  default: string,
  center: any
  [key:string] : any,
}

interface Styles {
  type: string;
  children: React.ReactNode;
  [key: string]: any;
}
interface ProductContainerStyles extends WithStyles<typeof ContainerStyles>{
  type: string
}
interface ProductInnerContainerStyles extends WithStyles<typeof InnerContainerStyles>{
  type: string
}
const styledBy = (property: string, mapping: TypeMapping) => (props: Styles) =>
  mapping[props[property]];

const ContainerStyles = {
  root: {
    flexDirection: styledBy('type', {
      default: 'row',
      center: 'row',
      right: 'row',
      left: 'row-reverse'
    }),
  }
}

const InnerContainerStyles = {
  root: {
    justifyContent: styledBy('type', {
      default: 'flex-start',
      center: 'center',
      right: 'flex-end',
      left: 'flex-start'
    }),
    paddingTop: styledBy('type', {
      default: '0',
      center: 20
    })
  }
}

const GridContainer = withStyles(ContainerStyles)(({ classes, type, ...other}:ProductContainerStyles ) => (
  <Grid className={classes.root + ' ' +SpacingStyles().spacing60} {...other}>
  </Grid>
))

const GridInnerContainer =  withStyles(InnerContainerStyles)(({ classes, type, ...other}:ProductInnerContainerStyles ) => (
  <Grid className={classes.root} {...other}>
  </Grid>
))

const ProductItem = ({ title, summary, description, imgUrl, imgAlt, typoPostion }: ProductItemType) => {
  const spacingClasses = SpacingStyles();
  const [descriptionData, setDescriptionData] = React.useState<Array<string>>([])
  React.useEffect(() => {
    const splitData = description.split('\n');
    setDescriptionData(splitData.filter((splitParagh: string) => splitParagh.length > 0))
  }, [description])
  return (
    <GridContainer type={typoPostion} container alignItems="center">
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <img src={imgUrl} alt={imgAlt} style={{ display: 'block', objectFit: 'contain', objectPosition: 'center', width: '100%', border: `1px solid ${myPalette.boarderGray}` }} />
      </Grid>
      <GridInnerContainer item xs={12} sm={12} md={6} lg={6} xl={6} container type={typoPostion}>
        <Grid item xs={11} container direction="column">
          <Typography variant="h4" className={spacingClasses.pb24}>{title}</Typography>
          {summary && <Typography variant="body2" className={spacingClasses.pb20}>{summary}</Typography>}
          {descriptionData.map((line: string, index: number) => (
            <Typography variant="body2" key={`0_${index}`} className={spacingClasses.pb12}>{line}</Typography>
          ))}
        </Grid>
      </GridInnerContainer>
    </GridContainer>
  )
}

export default ProductItem